.WorkoutsInput {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.WorkoutsInput > table {
  margin: 0 auto;
}

.WorkoutsInput > button {
  padding: 1em;
  margin: 1em auto;
}
